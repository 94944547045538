<template>
  <div>
    <div class="air__utils__heading"> <h5>Almacén de Contenedores Vacios</h5> </div>
    <my-breadcrumbs :routes="routes"/>
    <a-row class="mt-4">
      <a-tabs defaultActiveKey="1" type="card" size="large">
        <a-tab-pane key="1">
          <span slot="tab">
            Existencias de Contenedores
          </span>
          <div class="card">
            <div class="card-body text-center">
              <containersExistence/>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">
            Transacciones
          </span>
          <div class="card">
            <div class="card-body text-center">
              <containersTransactions/>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-row>
  </div>
</template>

<script>
import containersExistence from './containers_existence.vue'
import containersTransactions from './containers-transactions.vue'
export default {
  name: 'emptyContainersMain',
  components: {
    containersExistence,
    containersTransactions,
  },
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'operations',
          breadcrumbName: 'Operaciones',
        },
        {
          breadcrumbName: 'Proceso Operativo',
          name: 'operation_process',
        },
        {
          breadcrumbName: 'Almacén de Contenedores Vacios',
          aIcon: '',
        },
      ],
    }
  },

}
</script>
