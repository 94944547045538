<template>
  <div>
    <!-- Filtros -->
    <a-row align="middle">
      <a-col :sm="{ span: 24 }" :md="{ span: 11 }">
        <!-- SELECT Planta -->
        <customDropdownWorkCenterSelect v-model="workCenterIdFilter"/>
      </a-col>
      <a-col :sm="{ span: 22 }" :md="{ span: 11 }">
        <!-- SELECT Tipo de Contenedor -->
        <customDropdownSelect v-model="containerTypeIdFilter"
                               label="Tipo de Contenedor" resource="containerTypes"/>
      </a-col>
      <a-col :sm="{ span: 2 }" :md="{ span: 2 }">
        <b-button size="sm" variant="default" @click="FetchTable()">
           <b-icon icon="search"/>
        </b-button>
      </a-col>
    </a-row>
    <!-- Tabla y Modal con form -->
    <a-row align="middle">
      <!--TABLA-->
      <a-table bordered :scroll="{x:1300}" :columns="tableObj.columns"
          :data-source="tableObj.data"
          :pagination="tableObj.pagination"
          :loading="tableObj.loading"
          @change="FetchTable"
          :row-key="record => record.id">
          <template slot-scope="existence" slot="existence">
            {{parseInt(existence) || 0}}
          </template>
          <template slot="action" slot-scope="row">
            <b-button pill variant="secondary" @click="OpenModal(row)" v-if="row.existence > 0">
            <div class="d-none d-md-block" v-b-tooltip.hover title="Generar Salida">
              <b-icon icon="pencil-square"/> Generar Salida
            </div>
            <div class="d-md-none" v-b-tooltip.hover title="Generar Salida">
              <b-icon icon="pencil-square"/>
            </div>
            </b-button>
          </template>
      </a-table>
      <!--MODAL-->
      <b-modal  ref="modalForm" size="xl" @hide="CloseModal">
        <a-form-model ref="formRes" layout="horizontal" :model="formObj.form" :rules="formRules">
          <a-row align="middle">
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <!--NUMBER INPUT - Cantidad-->
              <a-form-model-item label="Cantidad" ref="quantity" prop="quantity">
                 <a-input-number v-model="formObj.form.quantity" :min="0" :step="1" size="large" style="width:100%"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <!--DATEPICKER - Fecha de baja-->
              <a-form-model-item  label="Fecha de baja" ref="transaction_dated_at" prop="transaction_dated_at">
                <a-date-picker  v-model="formObj.form.transaction_dated_at" placeholder="Fecha de baja" style="width:100%"
                                format="DD/MM/YYYY" size="large" valueFormat="YYYY-MM-DD"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row align="middle">
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <!--TEXT - Responsable de Baja-->
              <a-form-model-item label="Responsable de Baja" ref="transaction_responsible"  prop="transaction_responsible">
                 <a-input v-model="formObj.form.transaction_responsible" placeholder="Responsable de Baja" size="large"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <!--TEXT - Destino Final del Contenedor-->
              <a-form-model-item label="Destino Final del Contenedor" ref="final_destiny"  prop="final_destiny">
                 <a-input v-model="formObj.form.final_destiny" placeholder="Destino Final del Contenedor" size="large"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row align="middle">
            <!--TEXT-AREA - Observaciones-->
            <a-form-model-item label="Observaciones" ref="comments"  prop="comments">
              <a-textarea v-model="formObj.form.comments" auto-size :rows="5"/>
            </a-form-model-item>
            <!--TEXT-AREA -Observaciones -->
          </a-row>
          <a-row type="flex" justify="center">
            <b-alert :show="dismissCountDown" dismissible variant="danger"
              @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged">
              <p>La cantidad no puede ser mayor a la existencia</p>
              <b-progress variant="danger" :max="dismissSecs" :value="dismissCountDown" height="4px"/>
            </b-alert>
          </a-row>
          <!-- Botones -->
          <a-row type="flex" justify="end" v-show="!spinner">
            <a-col>
              <b-button pill variant="primary" @click="Post">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
              </b-button>
            </a-col>
          </a-row>
          <!-- Botones -->
        </a-form-model>
        <template v-slot:modal-footer>
          <simple-modal-footer @ClickEvent="CloseModal"/>
        </template>
      </b-modal>
    </a-row>
  </div>
</template>

<script>
import { apiV2Mixin } from '@/mixins/ApiV2Mixin'
const formDefault = {
  work_center_id: null,
  warehouse_empty_container_type: 'Salida',
  container_type_id: null,
  quantity: 1,
  transaction_dated_at: '',
  transaction_responsible: '',
  final_destiny: '',
  comments: '',
  formulation_id: null,
}
export default {
  name: 'containersExistence',
  mixins: [apiV2Mixin],
  data() {
    return {
      // filters
      workCenterIdFilter: undefined,
      containerTypeIdFilter: undefined,
      // table
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Planta',
            dataIndex: 'workCenterName',
            key: 'workCenterName',
            class: 'small text-center',
          },
          {
            title: 'Contenedor',
            dataIndex: 'containerTypeName',
            key: 'containerTypeName',
            class: 'small text-center',
          },
          {
            title: 'Existencia',
            dataIndex: 'existence',
            key: 'existence',
            class: 'small text-center',
            scopedSlots: { customRender: 'existence' },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
          },
        ],
      },
      // logics
      auxExistance: null,
      // dom
      spinner: false,
      dismissCountDown: 0,
      dismissSecs: 10,
      // form
      defaultForm: JSON.stringify(formDefault),
      formObj: {
        resourceType: 'warehouseEmptyContainers',
        form: {},
      },
      formRules: {
        warehouse_empty_container_type: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        quantity: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        transaction_responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        final_destiny: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
      },
    }
  },
  methods: {
    async FetchTable(pagE) {
      const params = {
        orderType: 'asc',
        orderField: 'work_center_id',
      }
      if (this.containerTypeIdFilter) params.containerTypeId = this.containerTypeIdFilter
      if (this.workCenterIdFilter) params.workCenterId = this.workCenterIdFilter
      await this.GetTableResourcev2(pagE, 'warehouseEmptyContainers/calculate', this.tableObj, params)
    },
    OpenModal(data = {}) {
      this.spinner = false
      this.formObj.form = JSON.parse(this.defaultForm)
      this.formObj.form.work_center_id = parseInt(data.work_center_id)
      this.formObj.form.container_type_id = parseInt(data.container_type_id)
      this.auxExistance = (parseInt(data.existence) > 0) ? parseInt(data.existence) : null
      this.$refs.modalForm.show()
    },
    CloseModal() {
      this.spinner = false
      this.formObj.form = JSON.parse(this.defaultForm)
      this.auxExistance = 0
      this.$refs.modalForm.hide()
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    async Post() {
      this.$refs.formRes.validate(async(valid) => {
        if (valid && (this.auxExistance === null || this.formObj.form.quantity <= this.auxExistance)) {
          await this.PostResourcev2(this.formObj, async () => {
            await this.FetchTable()
            this.CloseModal()
          })
        } else {
          this.showAlert()
          return false
        }
      })
    },
  },
  async mounted() {
    await this.FetchTable()
  },
}
</script>
