import { notification } from 'ant-design-vue'
// import { getPagination, setPagination, getPaginationv2, setPaginationv2 } from '@/helpers/tableHelpers'
import { getPaginationv2, setPaginationv2 } from '@/helpers/tableHelpers'
import api2 from '@/services/axios/index2'
export const apiV2Mixin = {
  data() {
    return {
      bvMonthCombo: [
        { value: '1', text: 'Enero' },
        { value: '2', text: 'Febrero' },
        { value: '3', text: 'Marzo' },
        { value: '4', text: 'Abril' },
        { value: '5', text: 'Mayo' },
        { value: '6', text: 'Junio' },
        { value: '7', text: 'Julio' },
        { value: '8', text: 'Agosto' },
        { value: '9', text: 'Septiembre' },
        { value: '10', text: 'Octubre' },
        { value: '11', text: 'Noviembre' },
        { value: '12', text: 'Diciembre' },
      ],
    }
  },
  methods: {
    // Helpers
    bvYearsCombo() {
      const max = new Date().getFullYear()
      const years = []

      for (let i = max; i >= 2020; i--) {
        years.push({ value: i.toString(), text: i.toString() })
      }
      return years
    },
    // Helpers
    // ****** FUNCIONES GENÉRICAS ****** //
    async errorLogic(error, errorCallback = null) {
      if (errorCallback && typeof errorCallback === 'function') {
        errorCallback(error)
      }
      // errorIterator(error)
      return error
    },
    async successLogic(msg = '', response = null, successCallback = null, errorCallback = null) {
      if (response) {
        notification.success({
          message: msg,
        })
        if (successCallback && typeof successCallback === 'function') {
          successCallback(response.data.data)
        }
        return response
      } else {
        if (errorCallback && typeof errorCallback === 'function') {
          errorCallback()
        }
        return {}
      }
    },
    // ****** FUNCIONES BASICAS AXIOS ****** //

    // ****** FUNCIONES BASICAS AXIOS ****** //
    // ****** **********************  ****** //
    // ****** **********************  ****** //
    // ****** FUNCIONES UTILES PARA DROPZONE  ****** //
    async DeleteFile(url, params, successCallback = null, errorCallback = null) {
      await api2.post(url, params)
        .then(async (response) => {
          if (successCallback && typeof successCallback === 'function') {
            successCallback(response.data)
          }
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
    },
    // ****** FUNCIONES UTILES PARA DROPZONE  ****** //
    // ****** FUNCIONES AXIOS NORMALES  ****** //
    async AxiosGetv2(url, params, successCallback = null, errorCallback = null) {
      let setResponse = null
      await api2.get(url, {
        params: params,
      })
        .then(async (response) => {
          setResponse = response.data
          if (successCallback && typeof successCallback === 'function') {
            successCallback(response.data)
          }
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
      return setResponse
    },
    async AxiosDelete(url, params, successCallback = null, errorCallback = null) {
      await api2.delete(url, params)
        .then(async (response) => {
          if (successCallback && typeof successCallback === 'function') {
            successCallback(response.data)
          }
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
    },
    // ****** FUNCIONES AXIOS NORMALES  ****** //
    // ****** FUNCIONES PARA Api V2 non JSON API URL ****** //
    async GetConcurrencyResources(paths, successCallback = null, errorCallback = null) {
      const setResponse = []
      const functionsget = []
      // const responseFormatter = new ResponseClass();
      paths.forEach((element, index) => {
        const promisecur = api2.get(paths[index].path, {
          params: paths[index].params,
        }).then(res => ({ res: res, promise: `promise${index}` }))
        functionsget.push(promisecur)
      })
      await Promise.all(functionsget)
        .then((response) => {
          response.forEach((element, ind) => {
            setResponse.push(element.res.data.data.data)
          })
          if (successCallback && typeof successCallback === 'function') {
            successCallback(setResponse)
          }
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
      return setResponse
    },

    async GetTableResourcev2(paginationEvent = {}, resourceType, tableObj, params = {}) {
      setPaginationv2(paginationEvent, tableObj, params)
      tableObj.loading = true

      await api2.get(`/${resourceType}`, {
        params: params,
      })
        .then((response) => {
          tableObj.data = response.data.data.data
          tableObj.loading = false
          tableObj.pagination = getPaginationv2(response.data.data)
        })
        .catch(async (error) => {
          await this.errorLogic(error)
        })
    },
    async PostResourcev2(form, successCallback = null, errorCallback = null) {
      if (!form) return {}
      let setResponse = {}
      await api2.post(`/${form.resourceType}`, form.form)
        .then(async (response) => {
          setResponse = await this.successLogic(
            'Se ha guardado el registro exitosamente.',
            response,
            successCallback,
            errorCallback,
          )
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
      return setResponse
    },
    async PutResourcev2(form, successCallback = null, errorCallback = null) {
      if (!form) return {}
      let setResponse = {}
      await api2.put(`/${form.resourceType}`, form.form)
        .then(async (response) => {
          setResponse = await this.successLogic(
            'Se ha actualizado el registro exitosamente.',
            response,
            successCallback,
            errorCallback,
          )
        })
        .catch(async (error) => {
          await this.errorLogic(error, errorCallback)
        })
      return setResponse
    },
  },
}
