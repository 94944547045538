<template>
  <div>
  <!-- Filtros -->
    <a-row align="middle">
      <a-col :sm="{ span: 24 }" :md="{ span: 11 }" :lg="{ span: 6 }">
        <!-- SELECT Planta -->
        <customDropdownWorkCenterSelect v-model="workCenterIdFilter"/>
      </a-col>
      <a-col :sm="{ span: 22 }" :md="{ span: 11 }" :lg="{ span: 6 }">
        <!-- SELECT Tipo de Contenedor -->
        <customDropdownSelect v-model="containerTypeIdFilter"
                               label="Tipo de Contenedor" resource="containerTypes"/>
      </a-col>
      <a-col :sm="{ span: 2 }" :md="{ span: 2 }" :lg="{ span: 2 }">
        <b-button size="sm" variant="default" @click="FetchTable()">
           <b-icon icon="search"/>
        </b-button>
      </a-col>
      <a-col :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span: 5 }">
        <!--DATEPICKER - Fecha final-->
        <a-form-model-item  label="Fecha final">
          <a-date-picker  v-model="startDate" placeholder="Fecha final" style="width: 100%"
                          format="DD/MM/YYYY" size="large" valueFormat="YYYY-DD-MM"/>
        </a-form-model-item>
        <!--DATEPICKER -Fecha final -->
      </a-col>
      <a-col :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span: 5 }">
        <!--DATEPICKER - Fecha final-->
        <a-form-model-item  label="Fecha final">
          <a-date-picker  v-model="finalDate" placeholder="Fecha final" style="width: 100%"
                          format="DD/MM/YYYY" size="large" valueFormat="YYYY-DD-MM"/>
        </a-form-model-item>
        <!--DATEPICKER -Fecha final -->
      </a-col>
    </a-row>
    <!-- Tabla -->
    <a-row>
      <a-table bordered :columns="tableObj.columns"
          :data-source="tableObj.data"
          :pagination="tableObj.pagination"
          :loading="tableObj.loading"
          :row-key="record => record.id"
          @change="FetchTable">
           <p slot="expandedRowRender" slot-scope="row">
             <strong>Observaciones:</strong> <br> {{ row.comments }}
          </p>
          <template slot="code" slot-scope="row">
            <strong v-if="row.formulations">{{row.formulations.code}}</strong>
            <span v-else>NA</span>
          </template>
          <template slot="details" slot-scope="row">
            <!-- <pre>
              {{row}}
            </pre> -->
            <strong>Planta:</strong> {{row.work_centers.name}}
            <div v-if="row.formulations">
              <hr class="bg-primary">
              <strong>Tipo de Proceso:</strong> {{row.formulations.formulation_types.name}}
              <hr class="bg-primary">
              <strong>Tipo de Formulación:</strong> {{row.formulations.process_types.name}}
            </div>
          </template>
          <template slot="dates" slot-scope="row">
            <span v-if="row.transaction_dated_at">{{moment(row.transaction_dated_at).format('DD/MM/YYYY HH:mm')}}</span>
            <span v-else>-</span>
          </template>
          <template slot="action" slot-scope="row">
            <b-button pill variant="secondary" @click="OpenModal(row.id)">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Editar">
                <b-icon icon="pencil-square"/> Editar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Editar">
                <b-icon icon="pencil-square"/>
              </div>
            </b-button>
          </template>
      </a-table>
    </a-row>
  </div>
</template>

<script>
import { apiV2Mixin } from '@/mixins/ApiV2Mixin'
export default {
  name: 'containersTransactions',
  mixins: [apiV2Mixin],
  data () {
    return {
      // filters
      workCenterIdFilter: undefined,
      containerTypeIdFilter: undefined,
      startDate: '',
      finalDate: '',
      // table
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Orden de Producción de Origen',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'code' },
          },
          {
            title: 'Detalle de procedencia',
            dataIndex: '',
            key: 'details',
            class: 'small text-center',
            scopedSlots: { customRender: 'details' },
          },
          {
            title: 'Tipo de Contenedor',
            dataIndex: 'container_types.name',
            key: 'container_types',
            class: 'small text-center',
          },
          {
            title: 'Número de Contenedores',
            dataIndex: 'quantity',
            key: 'quantity',
            class: 'small text-center',
          },
          {
            title: 'Tipo de Transacción',
            dataIndex: 'warehouse_empty_container_type',
            key: 'warehouse_empty_container_type',
            class: 'small text-center',
          },
          {
            title: 'Fechas',
            dataIndex: '',
            key: 'dates',
            class: 'small text-center',
            scopedSlots: { customRender: 'dates' },
          },
        ],
      },
      // dom
      spinner: false,
      // form
    }
  },
  methods: {
    async FetchTable(pagE) {
      const params = {
        orderField: 'id',
        orderType: 'desc',
      }
      if (this.containerTypeIdFilter) params.containerTypeId = this.containerTypeIdFilter
      if (this.workCenterIdFilter) params.workCenterId = this.workCenterIdFilter
      if (this.startDate) params.transactionInitialDatedAt = this.startDate
      if (this.finalDate) params.transactionFinalDatedAt = this.finalDate
      await this.GetTableResourcev2(pagE, 'warehouseEmptyContainers', this.tableObj, params)
    },
  },
  async mounted() {
    await this.FetchTable()
  },
}
</script>
